import React from "react";
import { Link } from "react-router-dom";

export default function Card(props) {
  const img = require(`../../images/${props.img}`);
  return (
    <Link to={`/${props.link}`}>
      <div className="card">
        <div
          className="card-image"
          style={{
            backgroundImage: `url("${img}")`,
          }}
        >
          <h3>{props.title}</h3>
        </div>
        <p>{props.info}</p>
      </div>
    </Link>
  );
}
