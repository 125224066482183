import React from "react";

export default function Slide(props) {
  return (
    <div className="slider">
      <div className={`slide prev ${props.scroll}`}>
        <img
          src={require(`../../images/${props.prev.img}`)}
          alt="carousel-prev"
        />
        <h2>{props.prev.description}</h2>
      </div>
      <div className={`slide current ${props.scroll}`}>
        <h2>{props.current.description}</h2>
        <img
          src={require(`../../images/${props.current.img}`)}
          alt="carousel-current"
        />
      </div>
      <div className={`slide next ${props.scroll}`}>
        <h2>{props.next.description}</h2>
        <img
          src={require(`../../images/${props.next.img}`)}
          alt="carousel-next"
        />
      </div>
    </div>
  );
}
