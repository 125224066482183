import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Dropdown(props) {
  const [start, setStart] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setStart(true);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setStart(false);
        setTimeout(() => {
          props.close();
        }, 300);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div
      className={`courses-dropdown ${start ? "start" : ""}`}
      ref={ref}
      onClick={() => props.close()}
    >
      <ul>
        <li>
          <Link to="/junior-music-course">Junior Music Course</Link>
        </li>
        <li>
          <Link to="/piano">Piano</Link>
        </li>
        <li>
          <Link to="/violin">Violin</Link>
        </li>
        <li>
          <Link to="/vocals">Vocals</Link>
        </li>
        <li>
          <Link to="/guitar">Guitar</Link>
        </li>
        <li>
          <Link to="/drums">Drums</Link>
        </li>
      </ul>
    </div>
  );
}
