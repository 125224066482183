export const JMC = {
  __html: `Developed over the past two decades, the Junior Music Course allows students to sample and learn several instruments and basic music theory before progressing to JMC courses below.
<br/><br/>
Our Junior Children Music Courses are catered to pre-schoolers, children between 3.5 to 6 years old, who may or may not be sure of their inclinations. These are fun and enjoyable, refreshing and stimulating conducted by caring, friendly, experienced teachers
<br/><br/><br/>
Duration 12 weeks
<br/><br/>
Lesson    weekly
<br/>
<br/>
Junior Children Music Course (JMC Instrument)
<br/><br/>
Alternatively, you can go direct into our beginner music courses focussed on
<br/><br/>
JMC Pianoforte - Children Piano Lessons
<br/><br/>
JMC Keyboard - Children Keyboard Lessons
<br/><br/>
JMC Violin - Children Violin Lessons
<br/><br/>
JMC Chldren Guitar / Ukulele Lessons
<br/><br/>
JMC Drums - Children Drums Lessons
<br/><br/><br/>
Duration  12 months
<br/><br/>
Lesson     45 min to 1 hour per session weekly
<br/><br/>
All courses are taught by our professionally trained, experienced, caring, friendly specialists, supported by our proven system. Courses are enjoyable and wholesome, fun and motivating.`,
};

export const piano = {
  __html: `This most fundamental and wholesome piano lessons are conducted individually or In small groups of up to 3 for up to an hour at regular, prearranged times each week. Ages are from 3+/4 years old up for our Juniors. Students will learn real skills and techniques capable of creating all forms of music whilst enjoying and appreciating them. They will learn from a specially selected repertoire to play, read and write music in a relaxed environment.
  <br/><br/>
Various tunes will be taught, to hone their skills for variety. Once you learnt the piano, it’s simpler for you to do the rest.
<br/><br/>
You can be a one-man ensemble. Get the right basics and skills here at KELs. Our studio has pianos for duo practice and performance.
<br/><br/>
Taught by qualified experienced, caring, lively specialists with years of experience in performance and teaching between them. At KELs, we have imbibed the knowledge from many best practices worldwide to come up with our proven system to support our teachers and students. Experience and enjoy.
<br/><br/>
Students are encouraged to take Graded Exams and get international accreditation to GCE levels whilst enjoying themselves. This builds confidence and gives students clear objectives and benchmarks. Some of our students achieved grade 8 after 6 years through our accelerated programme.`,
};

export const violin = {
  __html: `The violin is an extension of you. Express true passion. Control the mood.
  <br/><br/>
Learn from a specially selected repertoire to play, read and write music in a relaxed environment.
<br/><br/>
Various tunes will be taught, to hone your skills for variety. Learn to perform with grace and precision.
<br/><br/>
Lessons are conducted individually or in small groups of up to 3 for up to an hour at regular, prearranged times each week.
<br/><br/>
Ages are from 4 years up for our Juniors. Students will learn real skills and techniques capable of creating music whilst enjoying and appreciating them.
<br/><br/>
Learn advanced techniques and skills right here at KELs.
<br/><br/>
Taught by experienced, caring, lively instructors with years of experience in performance and teaching between them.
<br/><br/>
At KELs, we have imbibed the knowledge from many best practices worldwide to come up with our proven system to support our teachers and students.
<br/><br/>
Students are encouraged to take Graded Exams and get international accreditation to GCE levels whilst enjoying themselves. This builds confidence and gives students clear objectives and benchmarks. Some of our students achieve grade 8 after 6 years through our accelerated programme.`,
};

export const vocals = {
  __html: `Our Vocal lessons are carried out individually or in groups at a regular time each week. Ages are 6+.
  <br/><br/>
Our approach to learning vocal technique includes the use of microphones, performance, basic keyboard/piano skills. Students will learn to sing solo, to harmonise in groups. This is a multi-lingual course and students will benefit from speech training and memory techniques too.
<br/><br/>
Taught by experienced, caring, lively instructors with years of experience in performance, some are award winners.
<br/><br/>
At KELs, we have imbibed the knowledge from many best practices worldwide to come up with our proven system to support our teachers and students.
<br/><br/>
Students are encouraged to take Graded Exams and get international accreditation to GCE levels whilst enjoying themselves. This builds confidence and gives students clear objectives and benchmarks.`,
};

export const guitar = {
  __html: `Our Guitar lessons are carried out either individually or in small groups of up to 3 for an hour at a regular time each week.
  <br/><br/>
Students can begin playing a selected repertoire to learn and acquire more advanced skills and techniques while learning their favourite songs. Playing by ear is rote learning, limits the students’ potential. At KELs, you will be able to do more. You will learn to read, write and improvise music at the same time.
<br/><br/>
Our instructors are friendly, makes learning fun and enjoyable with years of experience in performance and teaching between them.
<br/><br/>
Students are encouraged to take Graded Exams and get international accreditation to GCE levels whilst enjoying themselves. This builds confidence and gives students clear objectives and benchmarks.`,
};

export const drums = {
  __html: `Our Drum lessons are individualized and customized. Play along to real tunes and learn to read, write and improvise music. Learn rhythms and drum techniques. Be ready to lead any band.
  <br/><br/>
Taught by experienced, caring, lively instructors with years of experience in performance and teaching between them.
<br/><br/>
At KELs, we have imbibed the knowledge from many best practices worldwide to come up with our proven system which our teachers are guided through.
<br/><br/>
Students are encouraged to take Graded Exams and get international accreditation to GCE levels whilst enjoying themselves. This builds confidence and gives students clear objectives and benchmarks.`,
};
