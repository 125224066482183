import React, { useState } from "react";
import { BrandFacebook } from "tabler-icons-react";
import axios from "axios";
import "./Footer.css";

export default function Footer(props) {
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const updateForm = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const formHandler = async (event) => {
    event.preventDefault();
    const url =
      "https://a6sspvr6wb.execute-api.ap-southeast-1.amazonaws.com/prod/send";
    console.log(form);
    if (form.phone.toString().length != 8) {
      alert("input a valid 8 digit phone number!");
    } else {
      setSubmitted(true);
      await axios.post(url, form);
    }
  };
  return (
    <div className="footer">
      <div className="contact">
        <div className="contact-container">
          <p>
            No.1 Jalan Anak Bukit
            <br />
            #02-17, Bukit Timah Plaza
            <br />
            Singapore 588996
            <br />
            <br />
            Tel: +65 6466 0989
            <br />
            <br />
            WhatsApp: 8149 7354
            <br />
            <br />
            <br />
            Opening Hours:
            <br />
            <br />
            Weekdays: 9AM - 8PM
            <br />
            Weekends: 9AM - 7PM
          </p>
          <br />
          <br />
          <a
            href="https://www.facebook.com/KELsmusicschool/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <BrandFacebook size={30} strokeWidth={1} />
          </a>
        </div>
      </div>
      <div className="inquire">
        {submitted ? (
          <p>Sent!</p>
        ) : (
          <form onSubmit={(event) => formHandler(event)}>
            <p>Send an enquiry!</p>
            <div className="form-contact">
              <label htmlFor="name" id="name">
                Name
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={(event) => updateForm(event)}
                  required
                />
              </label>
              <label htmlFor="phone" id="contact">
                Phone
                <input
                  type="number"
                  placeholder="12345678"
                  name="phone"
                  value={form.phone}
                  onChange={(event) => updateForm(event)}
                  required
                />
              </label>
            </div>
            <label htmlFor="email">
              Email
              <input
                type="email"
                name="email"
                id="email"
                value={form.email}
                onChange={(event) => updateForm(event)}
                required
              />
            </label>
            <label htmlFor="message" id="message">
              Message
              {window.innerWidth > 425 ? (
                <textarea
                  name="message"
                  cols="40"
                  rows="8"
                  value={form.message}
                  onChange={(event) => updateForm(event)}
                  required
                />
              ) : (
                <textarea
                  name="message"
                  cols="20"
                  rows="8"
                  value={form.message}
                  onChange={(event) => updateForm(event)}
                  required
                />
              )}
            </label>
            <button type="submit">Send</button>
          </form>
        )}
      </div>
    </div>
  );
}
