import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";

export default function Gallery(props) {
  const [index, setIndex] = useState(props.index);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.close();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const scrollLeft = () => {
    if (index > 0) {
      setIndex((prev) => prev - 1);
    }
  };

  const scrollRight = () => {
    if (index < props.images.length - 1) {
      setIndex((prev) => prev + 1);
    }
  };

  return (
    <div className="gallery" ref={ref}>
      <div className="gallery-nav">
        <ChevronLeft
          className={`left ${index === 0 ? "disable" : ""}`}
          onClick={() => scrollLeft()}
          size={48}
          strokeWidth={1}
        />
        <ChevronRight
          className={`right ${
            index === props.images.length - 1 ? "disable" : ""
          }`}
          onClick={() => scrollRight()}
          size={48}
          strokeWidth={1}
        />
      </div>

      <img
        src={require(`../../images/course/${props.images[index]}`)}
        alt="gallery-item"
      />
    </div>
  );
}
