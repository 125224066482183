import React from "react";
import Carousel from "./Carousel";
import "./Home.css";
import Intro from "./Intro";
import Courses from "./Courses";

export default function Home() {
  return (
    <>
      <Carousel />
      <Intro />
      <Courses />
    </>
  );
}
