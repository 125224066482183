import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./components/nav/Nav";
import Home from "./components/home/Home";
import Course from "./components/courses/Course";
import About from "./components/About";
import * as points from "./contents/course-points";
import "./App.css";
import Footer from "./components/home/Footer";
import ScrollToTop from "./ScrollToTop";
import * as info from "./contents/course-info";
import * as about from "./contents/about";

function App() {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Nav />
        <div className="content">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/about" exact>
              <About about={about.about} />
            </Route>
            <Route path="/junior-music-course" exact>
              <Course
                title="Junior Music Course"
                subTitle="Junior Music Course"
                points={points.JMC}
                body={info.JMC}
                headImg="jmc-head.jpg"
                images={[]}
              />
            </Route>
            <Route path="/piano" exact>
              <Course
                title="Piano"
                subTitle="Piano Lessons"
                points={points.piano}
                body={info.piano}
                headImg="piano-head.jpg"
                images={[
                  "Piano1.jpg",
                  "Piano2.jpg",
                  "pianoResult.jpg",
                  "PianoScore1-KELsMUSIC.jpg",
                  "PianoScore2-KELsMUSIC.jpg",
                  "PianoScore3-KELsMUSIC.jpg",
                ]}
              />
            </Route>
            <Route path="/violin" exact>
              <Course
                title="Violin"
                subTitle="Violin Lessons"
                points={points.violin}
                body={info.violin}
                headImg="violin-head.jpg"
                images={[
                  "Violin1.jpg",
                  "Violin2.jpg",
                  "violinResult.jpg",
                  "ViolinScore1-KELsMUSIC.jpg",
                  "ViolinScore2-KELsMUSIC.jpg",
                ]}
              />
            </Route>
            <Route path="/vocals" exact>
              <Course
                title="Vocals"
                subTitle="Vocal Lessons"
                points={points.vocals}
                body={info.vocals}
                headImg="vocals-head.jpg"
                images={["Vocals1.jpg"]}
              />
            </Route>
            <Route path="/guitar" exact>
              <Course
                title="Guitar"
                subTitle="Guitar Lessons"
                points={points.guitar}
                body={info.guitar}
                headImg="guitar-head.jpg"
                images={["Bands1.jpg", "Bands2.jpg", "Guitar1.jpg"]}
              />
            </Route>
            <Route path="/drums" exact>
              <Course
                title="Drums"
                subTitle="Drum Lessons"
                points={points.drums}
                body={info.drums}
                headImg="drums-head.jpg"
                images={[
                  "Drums1.jpg",
                  "Drums2.jpg",
                  "DrumsScore1-KELsMUSIC.jpg",
                  "DrumsScore2-KELsMUSIC.jpg",
                ]}
              />
            </Route>
          </Switch>
        </div>
        <Footer />
      </>
    </Router>
  );
}

export default App;
