import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, AlignJustified } from "tabler-icons-react";
import Dropdown from "./Dropdown";
import "./Nav.css";

export default function Nav() {
  const [dropdown, setDropdown] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    function isScrolled() {
      if (window.pageYOffset === 0) {
        setScroll(false);
        setMobileDropdown(false);
      } else {
        setScroll(true);
      }
    }
    window.onscroll = isScrolled;
  });

  const closeNav = () => {
    setMobileDropdown(false);
    setDropdown(false);
  };

  const reg = () => {
    return (
      <div className={`nav ${scroll ? "scroll" : ""}`}>
        <div className="nav-icon">
          <Link to="/">
            <h1>KELS</h1>
          </Link>
        </div>
        <div className="nav-items">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li onMouseDown={() => setDropdown(true)}>
            <div className="nav-course">
              <p>Courses</p>
              <ChevronDown
                className={`chev ${dropdown ? "open" : ""}`}
                size={24}
              />
            </div>
            {dropdown && <Dropdown close={() => setDropdown(false)} />}
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
        </div>
      </div>
    );
  };

  const mobile = () => {
    return (
      <div className={`nav ${scroll ? "scroll" : ""}`}>
        <div className="mobile-main-nav">
          <div className="nav-icon">
            <Link to="/">
              <h1>KELS</h1>
            </Link>
          </div>
          <div className="nav-items">
            <div>
              <AlignJustified
                onClick={() => setMobileDropdown((prev) => !prev)}
              />
            </div>
          </div>
        </div>
        {mobileDropdown && mobileMenu()}
      </div>
    );
  };

  const mobileMenu = () => {
    return (
      <div className="mobile-menu">
        <Link to="/" onClick={() => closeNav()}>
          Home
        </Link>
        <div onMouseDown={() => setDropdown(true)}>
          <div className="nav-course">
            <p>Courses</p>
            <ChevronDown
              className={`chev ${dropdown ? "open" : ""}`}
              size={24}
            />
          </div>
          {dropdown && <Dropdown close={() => closeNav()} />}
        </div>
        <Link to="/about" onClick={() => closeNav()}>
          About Us
        </Link>
      </div>
    );
  };

  return window.innerWidth > 425 ? reg() : mobile();
}
