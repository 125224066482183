import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";
import Slide from "./Slide";
import "./Carousel.css";

export default function Carousel() {
  const slides = [
    {
      img: "carousel1.jpg",
      description: "Creating Your Legacy",
    },
    {
      img: "carousel2.jpg",
      description: "Fully accredited music lessons",
    },
    {
      img: "carousel3.jpg",
      description: "Professional, experienced, caring, lively teachers",
    },
  ];
  const [slide, setSlide] = useState(0);
  const [scrLeft, setScrLeft] = useState(false);
  const [scrRight, setScrRight] = useState(false);
  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      scrollRight();
    }, 3500);
    return () => clearInterval(interval);
  }, [slide, scrRight, scrLeft]);

  const scrollSlide = (count) => {
    clearInterval(interval);
    setSlide((prev) => {
      let next = prev + count;
      if (next === slides.length) {
        return 0;
      }
      if (next === -1) {
        return slides.length - 1;
      }
      return next;
    });
  };

  const scrollLeft = () => {
    setScrLeft(true);
    setTimeout(() => {
      setScrLeft(false);
      scrollSlide(-1);
    }, 500);
  };

  const scrollRight = () => {
    setScrRight(true);
    setTimeout(() => {
      setScrRight(false);
      scrollSlide(1);
    }, 500);
  };

  const getPrev = () => {
    if (slide === 0) {
      return slides.length - 1;
    }
    return slide - 1;
  };

  const getNext = () => {
    if (slide === slides.length - 1) {
      return 0;
    }
    return slide + 1;
  };

  return (
    <div className="carousel">
      <ChevronLeft
        className="carousel-nav left"
        onClick={scrLeft ? undefined : () => scrollLeft()}
        size={48}
        strokeWidth={1}
      />
      <ChevronRight
        className="carousel-nav right"
        onClick={scrRight ? undefined : () => scrollRight()}
        size={48}
        strokeWidth={1}
      />
      <div className="front">
        <h1>KELS MUSIC SCHOOL</h1>
        <div className="button">
          <div className="circle"></div>
          <a href="#courses-overview">view our courses</a>
        </div>
      </div>
      <Slide
        prev={slides[getPrev()]}
        current={slides[slide]}
        next={slides[getNext()]}
        scroll={scrLeft ? "scroll-left" : scrRight ? "scroll-right" : ""}
      />
    </div>
  );
}
