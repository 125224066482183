import React from "react";

export default function Intro() {
  return (
    <div className="intro">
      <div className="about">
        <div className="intro-text">
          <h3>Inspiring Musicians since 1999</h3>
          <p>
            KELs develop creativity and intelligence in pre-school and young
            children
            <br />
            <br />
            Preparing students for DSA, Gifted Education, <br />
            and Music Elective programmes <br />
            <br />
            Thousands enjoyed interesting, fun lessons at KELs,
            <br /> achieving excellent results for over 20 years
          </p>
        </div>
      </div>
      <div className="split">
        <div className="split-text left">
          <div className="split-text-content">
            <h3>Education</h3>
            <p>
              Taught by qualified experienced, caring, lively specialists with
              years of experience in performance and teaching between them. At
              KELs, we have imbibed the knowledge from many best practices
              worldwide to come up with our proven system to support our
              teachers and students.
            </p>
          </div>
        </div>
        {window.innerWidth > 425 && <div className="split-img right" />}
      </div>
      <div className="split">
        {window.innerWidth > 425 && <div className="split-img left" />}
        <div className="split-text right">
          <div className="split-text-content">
            <h3>Exams</h3>
            <p>
              We encouraged students to take Graded Exams and get international
              accreditation to GCE levels whilst enjoying themselves. This
              builds confidence and gives students clear objectives and
              benchmarks. Some of our students achieved grade 8 after 6 years
              through our accelerated programme.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
