import React from "react";
import Point from "./Point";

export default function Body(props) {
  return (
    <div className="body">
      <div className="body-points">
        <h2>Why Choose Us?</h2>
        <div className="point-container">
          {props.points.map((point, index) => (
            <Point point={point} key={index} />
          ))}
        </div>
      </div>
      <div className="body-main">
        <h2>
          <span>{props.title}</span> <br />
          At KELs
        </h2>
        <p dangerouslySetInnerHTML={props.body}></p>
      </div>
    </div>
  );
}
