export const about = {
  __html: `Since 1999, KELs Music School has nurtured and trained thousands of students, guiding and instructing them musically. With unique and interesting music lessons specially designed for pre-schoolers and beginners, we have honed our craft. Our students and teachers are supported through our proven system, imbibed from the best practices worldwide.
<br/><br/>
Thousands enjoyed our star guidance, with unique, interesting and fun music lessons for all ages.
<br/><br/>
We train creativity and focus, honing your skills and keeping them nimble and sharp. Come learn our unique techniques, enjoy the fun and excel!
<br/><br/>
Let KELs empower you.
`,
};
