import React, { useState } from "react";
import "./Courses.css";
import Gallery from "./Gallery";
import Body from "./Body";

export default function Course(props) {
  const [gallery, setGallery] = useState(false);
  const [imgIndex, setImageIndex] = useState(-1);
  console.log(props.images);
  console.log(props.headImg);
  const openGallery = (index) => {
    setImageIndex(index);
    setGallery(true);
  };

  return (
    <div className="course">
      <div className="course-header">
        <h1>{props.title}</h1>
        <img
          src={require(`../../images/${props.headImg}`)}
          alt="course-front"
        />
      </div>
      <Body points={props.points} body={props.body} title={props.subTitle} />
      <div className="mini-gallery">
        {props.images.map((img, index) => (
          <img
            key={index}
            alt="gallery"
            src={require(`../../images/course/${img}`)}
            onClick={() => openGallery(index)}
          />
        ))}
      </div>
      {gallery && (
        <Gallery
          close={() => setGallery(false)}
          images={props.images}
          index={imgIndex}
        />
      )}
    </div>
  );
}
