export const JMC = `Our Junior Music Courses are catered to children between 3.5 to 6 years old, who may or may not be sure of their inclinations. Help them find their musical passion`;

export const piano = `The most wholesome and versatile instrument. Read and compose, find your true passion, or just relax as you like. KELs will train you to perform`;

export const violin = `The most expressive of musical instruments, an extension of your musical soul. KELs trains you to play with grace and precision`;

export const vocals = `Our Vocal lessons are carried out individually or in groups. Learn to sing solo, and harmonise in groups. A multi-lingual course`;

export const guitar = `Whether you just want to jam out, or shred some impressive solos, let your passion flow with KELs. Gain solid skills that will impress anyone`;

export const drums = `The heart and soul of all bands. Learn rhythm. Control the tempo. KELs trains you to lead any band`;
