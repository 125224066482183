import React from "react";
import "./About.css";

export default function About(props) {
  return (
    <div className="about-us">
      <div className="padding" />
      <h1>About KELs</h1>
      <p dangerouslySetInnerHTML={props.about}></p>
    </div>
  );
}
