import React from "react";
import * as overview from "../../contents/course-overview";
import Card from "./Card";

export default function Courses(props) {
  const cards = [
    {
      title: "JMC",
      link: "junior-music-course",
      info: overview.JMC,
      img: "jmc-course.jpg",
    },
    {
      title: "Piano",
      link: "piano",
      info: overview.piano,
      img: "piano-course.jpg",
    },
    {
      title: "Violin",
      link: "violin",
      info: overview.violin,
      img: "violin-course.jpg",
    },
    {
      title: "Vocals",
      link: "vocals",
      info: overview.vocals,
      img: "vocals-course.jpg",
    },
    {
      title: "Guitar",
      link: "guitar",
      info: overview.guitar,
      img: "guitar-course.jpg",
    },
    {
      title: "Drums",
      link: "drums",
      info: overview.drums,
      img: "drums-course.jpg",
    },
  ];
  return (
    <div className="courses" id="courses-overview">
      <h2>Courses</h2>
      <div className="course-cards">
        {cards.map((card, index) => (
          <Card
            title={card.title}
            img={card.img}
            link={card.link}
            info={card.info}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
