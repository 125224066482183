export const JMC = [
  "Sample and learn several instruments",
  "Caring, friendly, experienced teachers",
  "Learn basic music theory and more",
  "Fun and enjoyable, refreshing and stimulating",
  "Enjoy faster learning and better playing",
  "A course developed over the past two decades",
  "Integrate into the graded or skill courses",
  "Accelerated pathway achieve Grade 8 within 6 years",
];

export const piano = [
  "Pre-school, beginner to Diploma",
  "Star guidance-Outstanding results",
  "Trains focus, creativity and success",
  "Play, read, write, improvise all forms of music",
  "Supported by our proven system",
  "Prepare students for DSA, Gifted Education and Music Elective programmes",
  "Students achieve grade 8 after 6 years through our accelerated programme",
  "Over 20 years of experience, excellent results",
];

export const violin = [
  "Pre-school, beginner to Diploma",
  "Trains focus, harmony and success",
  "Intellectually stimulating",
  "Get the right basics and advanced techniques",
  "Supported by our proven system",
  "Prepare students for DSA, Gifted Education and Music Elective programmes",
  "Students achieve grade 8 after 6 years through our accelerated programme",
  "Over 20 years of experience, excellent results",
];

export const vocals = [
  "Sing solo",
  "Harmonise in groups",
  "Speech training and memory techniques",
  "Learn to project your voice",
  "Basic keyboard/piano skills",
  "Multi-lingual course",
  "Prepare students for DSA, Gifted Education and Music Elective programmes",
  "Over 20 years of experience, excellent results",
];
export const guitar = [
  "Excellent with kids, teens and adults",
  "Highly trained experienced  teachers",
  "Learn impressive techniques",
  "Play and sing your favourite songs",
  "Impress your friends",
  "Supported by our proven system",
  "Prepare students for DSA, Gifted Education and Music Elective programmes",
  "Over 20 years of experience, excellent results",
];
export const drums = [
  "Learn rhythm and drum techniques",
  "Music composition rudiments",
  "Learn by hearing",
  "Learn to read, write and improvise rhythms",
  "Outstanding results",
  "Supported by our proven system",
  "Prepare students for DSA, Gifted Education and Music Elective programmes",
  "Over 20 years of experience, excellent results",
];
